<template>
<div>
        <loading :active.sync="loading"></loading>
        <!--<link rel="stylesheet" href="https://unpkg.com/@coreui/icons/css/all.min.css">-->
        <div class="row"><div class="col-md-12">
          <b-card style="max-width: 860px;">
            <div slot="header">
              <div class="row">
            <div class="col-md-6">
              <strong>Mão de obra em {{ tipoProdutoSimples + "s" }} - ID: {{ id }}</strong>
            </div>
            <div class="col-md-6" style="text-align:right;">
              <b-button variant="success" size="sm" style="color:white;" @click="mop()">Editar</b-button>
              &nbsp;&nbsp;
              <b-button variant="link" size="sm" @click="ajuda()"><CIcon name="cil-lightbulb" />&nbsp;<small>Ajuda</small></b-button>
            </div>
            </div>
          </div>

        
        <table class="table">
            <thead>
            <tr>
                <th colspan="4">
                    <h2>{{ p.prd_name }}</h2>
                    <div>
                    <div style="float:left; font-size: 14px; margin-right:5px;">Receita para fabricar</div>
                    <div style="float:left"><b-form-input type="number" readonly class="amarelo pequeno centro" size="sm" v-model="unidadeVenda"></b-form-input></div>
                    <div style="float:left; font-size: 14px; margin-left:5px;"> {{ p.prd_descr }} de {{ p.prd_name }}.</div>
                    </div>
                </th>
                <th colspan="3">
                  <div style="text-align: right;">Total de mão de obra por {{ p.prd_descr }}:</div>
                    <h2 style="text-align: right; color: darkgreen;">{{ this.$session.get('regras')[0].grp_moeda }} {{ ( produto_total / unidadeVenda ) | grana }}</h2>
                    <div v-if="unidadeVenda > 1" style="text-align: right; font-size: 14px;">Total: <strong>{{ this.$session.get('regras')[0].grp_moeda }} {{ ( produto_total ) | grana }}</strong></div>
                </th>
            </tr>
            <tr>
                <th width="5%"></th>
                <th width="5%">Ordem</th>
                <!--<th width="20%">Etapa de Produção</th>-->
                <th width="35%">Mão de obra</th>
                <th width="10%"></th>
                <th width="15%">Valor</th>
                <th width="10%">Qtde</th>
                
                <th width="20%" style="text-align:right;">Subtotais</th>
            </tr>
            </thead>

            <tbody>
              <tr><td colspan="7">
                <div v-for="(etapaEmSi, index) in etapas" :key="index">
                  <div style="background-color:lightgrey;width:100%;padding:3px;height:30px;">
                    <div style="float:left"><strong>{{ etapaEmSi | toUpper }}</strong></div>
                    <div style="float:right">
                    </div>
                  </div>
                <table class="table">
                  <tbody v-for="(etapaItem, index) in etp" :key="index">
                  <tr v-if="etapaEmSi === etp[index].pmo_etapa">
                    <td width="5%" data-title="Ativar">
                      <b-form-checkbox @change.native="editar(index)"
                        v-model="etp[index].pmo_active" 
                      ></b-form-checkbox>
                    </td>
                    <td width="5%" data-title="Ordem">
                      <b-form-input class="verde centro" type="number" @blur.native="editar(index)" v-model="etp[index].pmo_num"></b-form-input>
                    </td>
                    <!--<td data-title="Etapa de Produção">
                      <b-form-input refs="etapas" class="verde esquerda" @blur.native="editarEtapa(etp[index].pmo_etapa, index)" v-model="etp[index].pmo_etapa"></b-form-input>
                    </td>-->
                    <td width="35%" data-title="Mão de obra">
                      <b-form-select class="verde" v-model="etp[index].mao_ID" :options="optionsMao"></b-form-select>
                    </td>
                    <td width="10%" data-title="Hora/Min.">
                      <b-form-select class="verde" style="background-image:none;" @change="editar(index)" v-model="etp[index].pmo_medida" :options="[{value: 'hora', text: 'Hora'},{value: 'min', text: 'Min.'}]"></b-form-select>
                    </td>
                    <td width="15%" data-title="Valor">
                      <vue-autonumeric class="form-control money amarelo direita" readonly :options="dinheiro" v-model="optionsMaoValor[etp[index].mao_ID][etp[index].pmo_medida]"></vue-autonumeric>
                    </td>
                    <td width="10%" data-title="Qtde">
                      <vue-autonumeric class="form-control verde centro" :options="numero" @blur.native="editar(index)" v-model="etp[index].pmo_qtde"></vue-autonumeric>
                    </td>
                    <td width="20%" data-title="Subtotais" style="text-align:right;">
                      <vue-autonumeric class="form-control money amarelo direita" readonly :options="dinheiro" v-model="etp_total_parcial[etp[index].pmo_ID]"></vue-autonumeric>
                    </td>
                </tr>
                </tbody>
                </table>
                </div>
              </td>
              </tr>
            </tbody>
        </table>
          </b-card>
          <b-modal size="lg" id="ajuda" hide-footer title="Uso de Mão de Obra">
      <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab no-body title="Tutorial" active>
            <b-card-text>
              <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/OIuJ5wPC0zs?t=229&rel=0"
              allowfullscreen
            ></b-embed>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      </div>
    </b-modal>
        </div></div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelSelect } from 'vue-search-select'
var vm
export default {
  props: {
    id: {
      required: true
    }
  },
  data () {
    return {
      optionsMao: [],
      optionsMaoValor: [],
      etapas: [],
      etapaItem: [],
      unidadeVenda: 1,
      sessao: '',
      loading: false,
      etp: [],
      p: [],
      pmoEtapa: '',
      pmoMao: '',
      pmoActive: 1,
      dinheiro: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 2
      },
      dinheiro2: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 3
      },
      percentual: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: ' %',
        currencySymbolPlacement: 's',
        decimalPlaces: 2
      },
      numero: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: '',
        decimalPlaces: 2
      },
      produto: 0,
      produto_total: 0,
      tipoProduto: '',
      tipoProdutoSimples: '',
      pmodiv: [],
      etp_total: [],
      etp_total_parcial: [],
      maodeobra_hora: [],
      maodeobra_minuto: [],
      etp_anterior: '',
      asetapas: []
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.produto = this.id
    this.verificaSessao()
  },
  filters: {
    grana: function (num) {
      if (typeof num !== 'undefined') {
        return String(parseFloat(num).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      }
    },
    somarArray: function (value) {
      var ret = 0
      for (var i = 0; i < value.length; i++) {
        ret = ret + parseFloat(value[i])
      }
      return ret
    },
    toUpper: function (value) {
      return value.toUpperCase()
    },
    capitalize: function (s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
  },
  methods: {
    verificaSessao: function () {
      if(isNaN(this.produto) || this.produto === 0) {
        this.$notify({
          type: 'danger',
          title: 'Falha ao encontrar produto',
          text: 'Inválido ou não encontrado'
        })
        return false
      }
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.recarregar()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    recarregaResto: function (force) {
      // Carrega a lista
      this.etp = []
      this.listar('pro_Produto_Maodeobra', 'pmo_etapa ASC, pmo_num ASC', '(prd_ID = ' + this.produto + ') AND (pmo_active = 1) AND (pmo_qtde > 0)').then(
        (r2) => {
          this.produto_total = 0
          this.etp = r2
          this.etapas = []
          for (var k = 0; k < r2.length; k++) {
            if (r2[k].pmo_medida === 'hora') {
              this.etp_total_parcial[r2[k].pmo_ID] = (r2[k].pmo_qtde * this.maodeobra_hora[r2[k].mao_ID])
            } else {
              this.etp_total_parcial[r2[k].pmo_ID] = (r2[k].pmo_qtde * this.maodeobra_minuto[r2[k].mao_ID])
            }
            this.produto_total += this.etp_total_parcial[r2[k].pmo_ID]
            this.etapas.push(r2[k].pmo_etapa)
            
            if (typeof this.asetapas[r2[k].pmo_etapa] === 'undefined') {
              this.asetapas[r2[k].pmo_etapa] = []
            }
            this.asetapas[r2[k].pmo_etapa].push(r2[k].pmo_ID)
            // if (etpListTemp[k].pmo_etapa === this.etp_anterior) {
            //  etpListTemp[k].pmo_etapa = ''
            // }
            // this.etp_anterior = r2[k].pmo_etapa
          }
          this.etapas = this.etapas.filter((v, i, a) => a.indexOf(v) === i) 
          this.loading = false
          this.carregaSimplesData(force)
        },
        (r2) => {
          this.$notify({
            type: 'danger',
            title: 'Dados insuficientes',
            text: 'Mão de obra não encontrada'
          })
          this.loading = false
          return false
        }
      )
    },
    recarregar: function (parcial) {
      this.loading = true
      if (parcial) {
        this.recarregaResto(true)
        return false;
      }
      this.listar('pro_Maodeobra', 'mao_ID', 'mao_active >= 0').then(
        (r) => {
          this.optionsMao = []
          this.optionsMaoValor = []
          if (r.length === 0) {
            this.$swal('Nenhuma mão de obra foi cadastrada', 'Você precisa cadastrar a mão de obra antes de acessar aqui. Acesse o menu TABELAS > MÃO DE OBRA.', 'error')
            this.loading = false
            return false
          }
          for(var i = 0; i < r.length; i++) {
            if (r[i].mao_custohora > 0) {
              this.maodeobra_hora[r[i].mao_ID] = r[i].mao_custohora
              this.maodeobra_minuto[r[i].mao_ID] = r[i].mao_customin
              this.optionsMao.push({ text: r[i].mao_funcao + (r[i].mao_name ? ' (' + r[i].mao_name + ')' : ''), value: r[i].mao_ID, disabled: !r[i].mao_active })
              this.optionsMaoValor[r[i].mao_ID] = { 'hora' : r[i].mao_custohora.toFixed(2), 'min' : (r[i].mao_customin ? r[i].mao_customin.toFixed(2) : 0) }
            }
          }
          if (!this.produto) {
            return false
          }
          this.listar('pro_Produto', 'prd_code, prd_name', '(prd_active = 1) AND (prd_ID = ' + this.produto + ')').then(
            (r2) => {
                if (!r2) {
                this.$notify({
                  type: 'danger',
                  title: 'Dados insuficientes',
                  text: 'Produto não encontrado'
                })
                this.loading = false
                return false
                }
                this.p = r2[0]
                this.tipoProduto = 'Produto'
                if (this.p.prd_tipo == 2) {
                  this.tipoProduto = 'Preparo'
                }
                this.tipoProdutoSimples = this.tipoProduto.toLowerCase()
                if (this.p.prd_descr) {
                  this.tipoProduto += '&nbsp;&nbsp;(' + this.p.prd_descr + ')'
                }
                this.unidadeVenda = this.p.prd_material
                if (this.unidadeVenda === null) {
                  this.unidadeVenda = 1
                }
                this.recarregaResto()
            },
            (r2) => {
                this.$notify({
                type: 'danger',
                title: 'Dados insuficientes',
                text: 'Produto inválido'
                })
                this.loading = false
            }
          )
        },
        (r) => {
          this.$notify({
            type: 'danger',
            title: 'Dados básicos necessários',
            text: 'Cadastre mão de obra antes.'
          })
          this.loading = false
        }
      )
    },
    atualizaUnidadeVenda: function () {
      if (this.unidadeVenda <= 0 || this.unidadeVenda === null || typeof this.unidadeVenda === 'undefined') {
        this.unidadeVenda = 1
      }
      this.atualizar('pro_Produto', {resource: [ {prd_material: this.unidadeVenda} ]}, 'prd_ID = ' + this.produto, null, '1').then(
        (response) => {
          console.log(response)
        },
        (response) => {
          this.$swal('Erro ao atualizar unidade de venda')
        }
      )
    },
    inserirItem: function () {
      this.loading = true
      // Vazio não
      if (!this.pmoEtapa || !this.pmoMao) {
        this.$swal('Preencha os campos antes de adicionar')
        this.loading = false
        return false
      }
      // Verifica se já existe
      this.campo('pro_Produto_Maodeobra', 'pmo_ID', '(pmo_etapa = ' + this.pmoEtapa + ') AND (mao_ID = ' + this.pmoMao + ') AND (prd_ID = ' + this.produto + ')').then(
        (response) => {
          // Se existe, apenas atualiza (reativa)
          /* this.atualizar('pro_Produto_Maodeobra', {resource: [ {pmo_active: 1} ]}, 'pmo_ID = ' + response).then(
            (response) => {
              this.$refs['modal-add'].hide()
              this.$swal('Esta etapa e mão de obra já foi inserida')
              this.recarregar(true)
            },
            (response) => {
              this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
              this.loading = false
            }
          )
          this.loading = false
          return false */
          var elem = {
            prd_ID: this.produto, 
            mao_ID: this.pmoMao,
            pmo_num: 1,
            pmo_etapa: this.pmoEtapa,
            pmo_active: 1, 
            pmo_qtde: 1,
            pmo_medida: 'hora',
            grp_ID: this.$session.get('grupo')
          }
          this.criar(elem)
        }, 
        (response) => {
          var elem = {
            prd_ID: this.produto, 
            mao_ID: this.pmoMao,
            pmo_num: 1,
            pmo_etapa: this.pmoEtapa,
            pmo_active: 1, 
            pmo_qtde: 1,
            pmo_medida: 'hora',
            grp_ID: this.$session.get('grupo')
          }
          this.criar(elem)
        }
      )
    },
    criar: function (el) {
      this.$http.post(this.URLApi + 'prolucro/_table/pro_Produto_Maodeobra', {resource: [ el ]}, {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then(
        (response) => {
          this.etp = []
          this.$refs['modal-add'].hide()
          this.recarregar()
        },
        (response) => {
          this.$swal('Falha ao inserir')
        }
      )
    },
    abreModal: function (etapa) {
      this.pmoEtapa = etapa
      this.$refs['modal-add'].show()
    },
    editar: function (index) {
      if (this.etp[index].pmo_qtde <= 0) {
        this.$swal('A quantidade não pode ser menor do que zero.')
        this.loading = false
        return false
      }
      this.loading = true
      this.atualizar('pro_Produto_Maodeobra', {resource: [ {prd_ID: this.produto, pmo_qtde: this.etp[index].pmo_qtde, 
      pmo_active: this.etp[index].pmo_active, pmo_num: this.etp[index].pmo_num, pmo_etapa: this.etp[index].pmo_etapa,
      mao_ID: this.etp[index].mao_ID, pmo_medida: this.etp[index].pmo_medida} ]}, 'pmo_ID = ' + this.etp[index].pmo_ID, '', '1').then(
        (response) => {
          if (!this.etp[index].pmo_active) {
            this.remover('pro_Produto_Maodeobra', 'pmo_ID = ' + this.etp[index].pmo_ID)
          }
          this.recarregar(true)
        },
        (response) => {
          this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
          this.loading = false
        }
      )
    },
    editarEtapa: function (etapaNome, index) {
      if (etapaNome === '') {
        this.$swal('A etapa precisa ser preenchida')
        this.loading = false
        return false
      }
      var prom = []
      this.campo('pro_Produto_Maodeobra', 'pmo_etapa', '(pmo_ID = ' + this.etp[index].pmo_ID + ')').then(
        (a) => {
          prom.push(this.atualizar('pro_Produto_Maodeobra', {resource: [ {pmo_etapa: etapaNome} ]}, '(pmo_etapa = ' + a + ')', '', '1'))
          Promise.all(prom).then(
            (p) => {
              this.recarregar()
            },
            (p) => {
              this.recarregar()
            }
          )
        },
        (a) => {}
      )
    },
    iip: function () {
      this.$router.push('/tabelas/produtosmateriais/' + this.produto)
    },
    mop: function () {
      this.$router.push('/tabelas/maodeobrauso/' + this.produto)
    },
    voltar: function (x) {
      if (x) {
        this.$router.push("/configuracoes/" + this.tipoProdutoSimples + "s")
      } else {
        this.$router.push("/precificador")
      }
    },
    carregaSimplesData: function (force) {
      // Verifica se é um produto de pré-preparo e atualiza o valor
      this.campos('pro_Material', '(prd_ID = ' + this.produto + ')').then(
        (x) => {
          if (x && x.mat_ID > 0) {
            // Pega o total de material em uso
            // this.campo('filtro_Produto', 'prd_custodireto_total', '(prd_ID = ' + this.produto + ')').then(
            //  (r) => {
                // Atualiza no pro_Material
                // this.atualizar('pro_Material', {resource: [ {mat_total: r, mat_value: r} ]}, '(mat_ID = ' + x + ')', '', '1')
            //  },
            //  (r) => {}
            // )
            var r = (parseFloat(x.mat_custohora || 0) + parseFloat(this.produto_total / this.unidadeVenda))
            this.atualizar('pro_Material', {resource: [ {mat_custominuto: parseFloat(this.produto_total / this.unidadeVenda), mat_total: r, mat_value: r} ]}, '(mat_ID = ' + x.mat_ID + ')', '', '1').then(
              (r) => {
                if (force) {
                  this.recarregar(false)
                }
              },
              (r) => {
                if (force) {
                  this.recarregar(false)
                }
              }
            )
          }
        },
        (x) => {
          if (force) {
            this.recarregar(false)
          }
        }
      )
    },
    ajuda: function() {
      this.$bvModal.show('ajuda')
    }
  },
  components: {
    Loading,
    VueAutonumeric,
    ModelSelect
  }
}
</script>